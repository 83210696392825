import React from "react"
import { Link } from "gatsby"
import { Twitter, GitHub, Linkedin, Camera } from "react-feather"
import { rhythm } from "../utils/typography"

import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  a {
    color: #1abc9c;
  }
  h1 {
    margin-top: 1rem;
  }
`

const Layout = ({ title, children, social, large }) => {
  let header
  let showRebrand = false
  console.log(large)
  const isSSR = typeof window === "undefined"
  if (!isSSR) {
    const urlParams = new URLSearchParams(window.location.search)
    showRebrand = urlParams.get("show-rebrand")
  }

  header = (
    <h3
      style={{
        fontFamily: `Montserrat, sans-serif`,
        marginTop: 0,
        display: `inline`,
      }}
    >
      <Link
        style={{
          boxShadow: `none`,
          textDecoration: `none`,
          color: `inherit`,
        }}
        to={`/`}
      >
        {showRebrand && (
          <>
            <div
              style={{
                display: "inline-block",
                opacity: "0.4",
              }}
            >
              Korevec
            </div>
            {` is now `}
          </>
        )}
        <span style={{ color: "#1abc9c" }}>{title || "Bianchi"}</span>
      </Link>
    </h3>
  )

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: `${large ? "58rem" : rhythm(24)}`,
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>
        <GlobalStyles />
        <div style={{ display: `flex`, height: `40px` }}>
          {header}
          <ul
            style={{
              display: `inline-flex`,
              listStyle: `none`,
              marginLeft: "auto",
            }}
          >
            {social.map((item) => {
              return (
                <li key={item.title}>
                  <a
                    target="_BLANK"
                    rel="noopener noreferrer"
                    style={{ boxShadow: `none`, padding: "10px" }}
                    href={item.url}
                  >
                    {(() => {
                      switch (item.title) {
                        case "Twitter":
                          return <Twitter />
                        case "Github":
                          return <GitHub />
                        case "Linkedin":
                          return <Linkedin />
                        case "Photo":
                          return <Camera />
                        default:
                          return item.title
                      }
                    })()}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </header>
      <main>{children}</main>
      <footer></footer>
    </div>
  )
}

export default Layout
